import React, { Component } from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import logo from './ddxedlogo.png';
import phone from './Phone.png';
import comp from './comp.png';
import steth from './steth.png';
import body from './body.png';
import needle from './needle.png';
import graph from './graph.png';
import doc from './doc.png';
import kacper from './kacper.png';
import brian from './brian.png';
import vid_dd from './vid_dd.mp4';

import mcgill from './mcgill.png';
import './App.css';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dates: [],
      email: undefined,
      notifyMessage: undefined
    }

    this.createSubscriber = this.createSubscriber.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.hideMessageCtn = this.hideMessageCtn.bind(this);
  }

  hideMessageCtn() {
    this.setState({
      notifyMessage: undefined
    });
  }


  handleChange(e) {
    this.setState({ email: e.target.value });
  }

  createSubscriber() {
    let email = this.state.email
    let data = {
      email: email,
      source: "ddxed"
    }

    const state = this;

    (async () => {
      var myHeaders = new Headers({
        "Content-Type": "application/json"
      });
      let url = "https://niburski-homes.herokuapp.com/api/email_subscribers"
      // url = "http://localhost:4000/api/email_subscribers"

      var myInit = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(data)
      };
      var myRequest = new Request(url, myInit);

      const rawResponse = await fetch(myRequest)
      const content = await rawResponse.json();

      state.setState({
        notifyMessage: "Thanks for subscribing!"
      });

      setTimeout(() => {
        state.setState({
          notifyMessage: undefined
        })
      }, 7000)

      return content;
    })();
  }

  componentDidMount() {
  }

  render() {
    const notify = this.state.notifyMessage;
    let notifyDetail = <div></div>

    if (notify !== undefined) {
        notifyDetail = <div onClick={this.hideMessageCtn} className="notification-ctn notify">
          {notify}
        </div>
    }

    return (
      <div className="App">
        <div id="home" className="App-header">
          <header className="header">
            <a href="#home" className="logo">
              <img src={logo} />
            </a>
            <input className="menu-btn" type="checkbox" id="menu-btn" />
            <label className="menu-icon" htmlFor="menu-btn"><span className="navicon"></span></label>
            <ul className="menu">
              <li><a href="#what-we-do">What we do</a></li>
              <li><a href="#about">About us</a></li>
              <li><div className="btn"><a href="#banner">Get started</a></div></li>
            </ul>
          </header>

          <div className="flex apart">
            <div className="fullscreen-bg">
              <img className="phone-img" src={phone} />
            </div>

            <div id="signup" className="text">
              <canvas id="particles"></canvas>
              <div className="main-cta">
                <h3>Bye complexity, hello simplicity.</h3>
                <h1>Differentials made easy.</h1>

                <h5>Understand the best test, diagnostics, and next steps in even the most complex cases, with up to date evidence and probabilities.</h5>

                <div className="email-subscribe">
                  <input
                    placeholder="Your email address"
                    className="input"
                    value={this.state.email}
                    onChange={ this.handleChange.bind(this) }/>
                  <div onClick={this.createSubscriber} className="btn">Join us</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="comp" className="section info split">
          <div className="left">
            <h3 className="">Consider all the presentations.</h3>
            <h1 className="">Simplify your cases.</h1>

            <h5>Illness is never just common. It presents differently for each different person. ddxed helps simplify the complexity, shows all alternatives, and gives you an understanding of all the diseases likely for one result. </h5>

            <div className="items flex">
              <a href="#signup"><div className="btn square">Join the beta</div></a>
            </div>
          </div>

          <div className="right">
            <div className="comp-holder">
              <img src={comp} className="computer-img"/>
              <video playsInline autoPlay muted loop className="video-inside-comp">
                <source src={vid_dd} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>

        <div id="what-we-do" className="section our-scans">
          <h5 className="">Made for medicine</h5>
          <h3 className="">What <span>ddxed</span> offers</h3>

          <div className="scans flex">

              <div className="scan">
                <img src={steth} />
                <h4>All differentials</h4>
                <h5>Get all the possibilities for every disease that exists.</h5>
              </div>


              <div className="scan">
                <img src={graph} />
                <h4>Up-to-date evidence</h4>
                <h5>The most accurate data and pretest probabilities available.</h5>
              </div>


              <div className="scan">
                <img src={needle} />
                <h4>Treatments</h4>
                <h5>Consider all possibility treatment modalities and their overlap.</h5>
              </div>


              <div className="scan">
                <img src={body} />
                <h4>Clinical investigations</h4>
                <h5>Find out the relevant tests to narrow down your differential.</h5>
              </div>
          </div>
        </div>

        <div id="banner" className="section info split forced-full">
          <h1>Exclusive chance to <span className="blue">join the beta</span></h1>

          <div className="ctn">
            <a href="#signup"><div className="btn square">Sign up</div></a>
          </div>
        </div>

        <div id="about" className="section info split forced-full">
          <div className="left">
            <h1 className="">Who we are</h1>

            <div className="items flex">
              <div className="item">
                <img src={kacper} />
                <h5>Kacper Niburski</h5>
              </div>

              <div className="item">
                <img src={brian} />
                <h5>Brian (Yibin) Dang</h5>
              </div>
            </div>

            <h1 className="">Our support</h1>

            <div className="items flex">
              <img src={mcgill} />
            </div>
          </div>

          <div className="right">
            <img src={doc} />
          </div>
        </div>

        <div className="section footer">
          <div className="logo">
            <img src={logo} />
          </div>

          <div className="other">
            <div className="left">
              <div className="column">
                <h4>DDxed</h4>
                <h5><a href="http://bit.ly/DDxedApp" target="_blank">Subscribe</a></h5>
                <h5><a href="#about">Support</a></h5>
              </div>

              <div className="column second">
                <h4><a href="#scans">Follow Us</a></h4>
                <h5><a href="https://www.youtube.com/watch?v=Xxdedx1HtHo" target="_blank">Facebook</a></h5>
                <h5><a href="https://www.youtube.com/watch?v=Xxdedx1HtHo" target="_blank">Twitter</a></h5>
              </div>
            </div>

            <div className="right">
              Get <a href="http://bit.ly/DDxedApp" target="_blank"><span>tips</span></a> weekly
            </div>
          </div>
        </div>

        {notifyDetail}
      </div>
    );
  }
}

export default App;
